import React, { Component } from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { Link } from '@fluentui/react/lib/Link';
import authService from '../api-authorization/AuthorizeService'
import { Get, GetHtml } from '../../http';
import { createMarkup, isEmptyStr } from '../../functions';

export class Knowledgebase extends Component {
    static displayName = Knowledgebase.name;

    constructor(props, appState) {
        super(props, appState);

        let solution = (this.props.match.params) ? this.props.match.params.solution : "";
        let page = (this.props.match.params) ? this.props.match.params.page : "";
        if (isEmptyStr(page))
            page = solution + ".html";

        this.loadPage(solution, page);

        this.state = {
            solution: "",
            solutionDisplayName: "",
            title: "",
            page: "",
            navContent: "",
            htmlContent: ""
        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {

            let solution = (this.props.match.params) ? this.props.match.params.solution : "";
            let page = (this.props.match.params) ? this.props.match.params.page : "";
            if (isEmptyStr(page))
                page = solution+".html";
            this.loadPage(solution, page);

        }
    }

    loadPage(solution, page) {
        authService.getAccessToken().then((token) => {

            Get("api/solutions/byname(" + solution + ")", token).then((data) => {
                this.setState({
                    title: data.displayName,
                    solution: solution,
                    solutionDisplayName: data.displayName,
                });
            }).catch((err) => {
                this.setState({ title: "Unknown Solution" });
            });

            GetHtml("/pages/knowledgebase/" + solution + "/nav.html", token).then((nav) => {
                this.setState({
                    navContent: nav
                });
            }).catch((err) => {
                this.setState({ htmlContent: "Page not found" });
            });

            GetHtml("/pages/knowledgebase/"+ solution + "/" + page, token).then((html) => {
                this.setState({
                     htmlContent: html
                });
            }).catch((err) => {
                this.setState({ htmlContent: "Page not found" });
            });


        });
    }


  render () {
    return (
        <div className="card" style={{ marginBottom: '10px' }}>

			<div className="card-header">
                <h4>{ this.state.title }</h4>
			</div>

			<div id="content" className="card-body">

                <div dangerouslySetInnerHTML={createMarkup(this.state.htmlContent)} />

			</div>
		</div>
    );
    }

}

