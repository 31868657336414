import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { GetHtml } from '../../http';
import { createMarkup } from '../../functions';

export class Home extends Component {
  static displayName = Home.name;


	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			isAuthenticated: false,
			firstName: null,
			htmlContent: ""
		};
	}

	componentDidMount() {
		this._subscription = authService.subscribe(() => this.populateState());
		this.populateState();
	}

	componentWillUnmount() {
		authService.unsubscribe(this._subscription);
	}

	async populateState() {
		const [isAuthenticated, user, token] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getAccessToken()]);
		let homePage = (isAuthenticated) ? "/pages/home.html" : "/home.html";
		let firstName = user && user.given_name;
		GetHtml(homePage, token).then((html) => {
			this.setState({
				isAuthenticated: isAuthenticated,
				firstName: firstName,
				htmlContent: html.replace("{firstName}", firstName)
			}, this.setState({ loading: false }));
		}).catch((err) => {
			this.setState({ isAuthenticated: isAuthenticated, firstName: firstName, htmlContent: "Page not found" }, this.setState({ loading: false }));
		});

		/*
		this.setState({
			isAuthenticated,
			firstName: user && user.given_name
		}, this.setState({ loading: false }));
		*/
	}

  render () {
	  const renderText = () => {
		  return <div dangerouslySetInnerHTML={createMarkup(this.state.htmlContent)} />
	  };
	  /*
	  const { isAuthenticated, firstName } = this.state;
	  const renderText = () => {
          if (isAuthenticated) {
			  return <span>
				  Welcome to the portal {firstName}.
				<br /><br />
				In this portal you can manage your license keys and download solutions and other things.
				</span>;
			} else {
			  return <span>
				Welcome to the portal.
				<br /><br />
				Click <a href="authentication/login">here</a> to login or to register as a new user.
				<br /><br />
				After logging in you can manage your license keys and download solutions and other things.
				</span>;
			}
	  }
	  */

	  return (
		<div className="card">

			<div className="card-header">
				<h4>About this portal</h4>
			</div>

			<div className="card-body">

				  <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 250px)', width: '100%' }}>
					  <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

						<div className="card-text">
							  {this.state.loading && <Spinner size={SpinnerSize.large} />}
							  {!this.state.loading && renderText()}
						</div>

					  </ScrollablePane>
				  </div >	


			</div>
		</div>
    );
  }
}
