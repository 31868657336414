//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { stringtoNumber, formatNumber, addMonths } from '../../functions';

interface IDialogOutput {
    userLicenseId: string;
    validTo: Date;
    userCount: number;
    scope: string;
    tenantid: string;
}

interface IDialogProps {

}

interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    solutionUniqueName: string;
    host: string;
    validTo: Date;
    userCount: number;
    scope: string;
    tenantid: string;
}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    currentLicense: ILicense;
    validTo: Date;
    userCount: number;
    maxDate: Date;
    scope: string;
    tenantid: string;
    scopeOptions: IDropdownOption[]
}

export class Admin_ChangeLicenseDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

      this._onCancelClick = this._onCancelClick.bind(this);
      this._onOkClick = this._onOkClick.bind(this);
      this.validate = this.validate.bind(this);

      let now = new Date();
      this.state = {
          title: "Change license",
          hide: true,
          invalid: true,
          currentLicense: { userLicenseId: null, solutionId: null, solutionUniqueName: null, solutionDisplayName: null, host: null, userCount: 0, validTo: null, scope: "User", tenantid: null },
          validTo: null,
          userCount: 0,
          maxDate: addMonths(now, 13),
          scope: "User",
          tenantid: null,
          scopeOptions: [{ key: "User", text: "User" }, { key: "Environment", text: "Environment" }, { key: "Tenant", text: "Tenant" }]
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >
                <TextField key="solution" label="Solution" readOnly value={this.state.currentLicense.solutionDisplayName} />
                <TextField key="host" label="Environment url" readOnly value={this.state.currentLicense.host} />

                <br />

                <Dropdown key="scope" label="Scope" options={this.state.scopeOptions} selectedKey={this.state.scope} multiSelect={false} onChange={(evt: any, option?: IDropdownOption, index?: number) => {
                    if (option) {
                        if (option != undefined && option != null) {
                            let userCount = this.state.userCount;
                            let tenantId = this.state.tenantid;
                            if (option.key == "Environment") {
                                userCount = 0;
                                tenantId = null;
                            } else if (option.key == "Tenant") {
                                userCount = 0;
                                tenantId = null;
                            }
                            this.setState({ scope: option.key as string, userCount: userCount, tenantid: tenantId }, () => {this.validate(); });
                        }
                    }
                }} >
                </Dropdown>

                {this.state.scope == "User" && (
                    <>
                        <br />

                        <SpinButton key="userCount" min={0} max={999} iconButtonProps={{ style: { display: 'none' } }} /*isRequired={true}*/
                            value={(this.state.userCount != null) ? formatNumber(this.state.userCount, 0) : ""} label="Users"
                            onValidate={(value: string, evt: React.SyntheticEvent<HTMLElement>) => {
                                let num = stringtoNumber(value, ",");
                                if (isNaN(num)) {
                                    this.setState({ userCount: null }, () => { this.validate(); });
                                    evt.preventDefault();
                                    return;
                                }
                                this.setState({ userCount: num }, () => { this.validate(); });
                                return formatNumber(num, 0);
                            }}
                        />
                    </>)}

                {this.state.scope == "Tenant" && (
                    <>
                        <br />

                        <TextField key="tenantid" label="Tenant ID" value={this.state.currentLicense.tenantid} onChange={(evt, value) => {
                            this.setState({ tenantid: value }, () => { this.validate(); });
                        }} />
                    </>)}

                <br />

                <DatePicker key="validTo" label="Valid to"
                    isRequired={true}
                    value={this.state.validTo}
                    allowTextInput={false}
                    showGoToToday={true}
                    maxDate={this.state.maxDate}
                    formatDate={(date: Date | string) => {
                        if (date != undefined && date != null)
                            if (date instanceof Date) {
                                return date.toLocaleDateString('nl-NL');
                            } else {
                                return new Date(date).toLocaleDateString('nl-NL');
                            }
                        else
                            return "";
                    }}
                     onSelectDate={(date: Date | null | undefined) => {
                        if (date != this.state.validTo) {
                            this.setState({ validTo: date }, () => { this.validate(); });
                        }
                    }}
                    textField={{ styles: { fieldGroup: { selectors: { ":before": { content: "" } } } } }}
                    parseDateFromString={(val: string) => {
                        if (val == undefined || val == null || val == "")
                            return null;
                        let spl = (val.indexOf("-")) ? "-" : (val.indexOf("/")) ? "/" : "";
                        let parts = val.split(spl); //d-m-y
                        if (parts.length == 3) {
                            const now = new Date();
                            let day = Number(parts[0]);
                            let month = Number(parts[1]) - 1;
                            let year = Number(parts[2]);
                            if (year < 100)
                                year += now.getFullYear() - (now.getFullYear() % 100);
                            return new Date(year, month, day);
                        } else {
                            return null;
                        }

                    }}
                />
                          
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    validate() {
        let now = new Date();
        let nextYear = addMonths(now, 13);

        let valid: boolean = (
            this.state.validTo !== null &&
            this.state.validTo <= nextYear &&
            ((this.state.scope == "User" && this.state.userCount !== null && this.state.userCount > 0) || this.state.scope == "Environment" || (this.state.scope == "Tenant" && this.state.tenantid !== null))
            //(this.state.validTo !== this.state.currentLicense.validTo || this.state.userCount !== this.state.currentLicense.userCount));
        );
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });            
        this._cbOk({ userLicenseId: this.state.currentLicense.userLicenseId, validTo: this.state.validTo, userCount: this.state.userCount, scope: this.state.scope, tenantid: this.state.tenantid });
    }

    private reset() {
        let now = new Date();
        this.setState({
            validTo: null,
            userCount: 0,
            maxDate: addMonths(now, 13),
            scope: "User"
        });
    }

    public Show(license: ILicense, onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;
        let now = new Date();
        this.setState({ hide: false, currentLicense: license, validTo: new Date(license.validTo), userCount: license.userCount, invalid: true, maxDate: addMonths(now, 13), scope: license.scope, tenantid: license.tenantid });

    }

}