import * as React from 'react';

import { DetailsList, DetailsListLayoutMode, ConstrainMode, IColumn, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps } from '@fluentui/react/lib/DetailsList';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import authService from '../api-authorization/AuthorizeService';
import { Get, GetBlob } from '../../http';
import { ErrorDialog } from '../dialogs/error.dialog';
import { extractError } from '../../functions';

interface IProps {

}

interface IState {
    loading: boolean;
    items: IItem[];
    navs: IBreadcrumbItem[];
}

interface IItemResult {
    //ParentPath: string;
    currentPath: string;
    items: IItem[];
}

interface IItem {
    parentPath: string;
    name: string;
    type: string;
    //Date: string;
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const renderFileIcon = (name: string) => {
    const ext = /\.([a-z]+)/.exec(name)
    if (ext == null || ext.length == 0) return <Icon iconName="FabricFolder" />
    return (
        <img
            src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${ext[1]}.svg`}
            className={classNames.fileIconImg}
            alt={`file icon`}
        />
    )
}

export class DownloadExplorer extends React.Component<IProps, IState> {

    private _errorDialog = React.createRef<ErrorDialog>();

    constructor(props: IProps, appState: IState) {
        super(props, appState);

        this.onNavItemClicked = this.onNavItemClicked.bind(this);

        this.state = {
            loading: true,
            items: [],
            navs: []
        };


    }

    componentDidMount() {
        this.populateData('');
    }

    async populateData(parentPath: string) {
        let _self = this;
        authService.getAccessToken().then((token) => {            
            Get("api/downloads/explore?path="+encodeURIComponent(parentPath), token).then((data: IItemResult) => {

                let path = '';  
                let navs: IBreadcrumbItem[] = [
                    { key: '', text: 'Downloads', onClick: _self.onNavItemClicked }
                ].concat(
                    data.currentPath.split('\\').filter(f => f != '').map((p, i) => {
                        if (path === '') {
                            path = p;
                        } else {
                            path = path + '\\' + p;
                        }
                        return { key: path, text: p, onClick: _self.onNavItemClicked };
                    })
                );
                _self.setState({ items: data.items, navs: navs, loading: false });
            });
        });
    }

    render() {
        return (
            <div className="card" style={{ marginBottom: '10px' }}>

                <div className="card-header">
                    <h4>Downloads</h4>
                </div>

                <div id="content" className="card-body">



                                    <Breadcrumb styles={{
                                        itemLink: { fontSize: 12 }, chevron: { fontSize: 12 }, list: { fontSize: 12 }, listItem: { fontSize: 12 }, item: { fontSize: 12 }, overflow: { fontSize: 12 }, overflowButton: { fontSize: 12 }, root: { fontSize: 12 }
                                    }}
                                        items={this.state.navs}
                                        //maxDisplayedItems={2}
                                        //overflowIndex={((this.state.navs.length<2)?0:1)}
                                        overflowAriaLabel="More links"

                                    />

                                    <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 300px)', width: '100%' }}>
                                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

            <DetailsList
                items={this.state.items}
                compact={true}
                columns={[
                    {
                        key: 'column1',
                        name: 'File Type',
                        className: classNames.fileIconCell,
                        iconClassName: classNames.fileIconHeaderIcon,
                        iconName: 'Page',
                        isIconOnly: true,
                        minWidth: 16,
                        maxWidth: 16,
                        onRender: (item: IItem) => renderFileIcon(item.name),
                    },
                    {
                        key: 'column2',
                        name: 'Name',
                        fieldName: 'name',
                        minWidth: 210,
                        maxWidth: 350,
                        onRender: (item: IItem) => <span onClick={(ev) => {
                            if (item.type == "Folder") {
                                this.loadFolder(item);
                            }
                        }}>{item.name}</span>
                    },
                    //{
                    //    key: 'column3',
                    //    name: 'Date',
                    //    minWidth: 70,
                    //    maxWidth: 200,
                    //    onRender: (item: IItem) => <span>{item.Date}</span>
                    //},
                    // and so on for more columns
                ]}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                onItemInvoked={(item: IItem) => {
                    if (item.type == "File") {
                        this.downloadFile(item);
                    }
                }}
                />

                 <ErrorDialog ref={this._errorDialog} />

                { this.state.loading && (
                    <Stack {...{ horizontalAlign: 'center', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} >
                        <Spinner size={SpinnerSize.large} />
                        <span>Loading...</span>
                    </Stack>
                )}                                                  

                      </ScrollablePane>                     
                    </div >


                </div>
            </div>
        );
    }

    loadFolder(item: IItem) {
        let path = (item.parentPath === '') ? item.name : item.parentPath + '\\' + item.name;
        this.populateData(path);
    }

    downloadFile(item: IItem) {
        this.setState({ loading: true });
        let path = (item.parentPath === '') ? item.name : item.parentPath + '\\' + item.name;
        authService.getAccessToken().then((token) => {
            GetBlob("api/downloads/downloadfile?file=" + encodeURIComponent(path), token).then((blob) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.setAttribute("download", item.name);
                a.click();
                this.setState({ loading: false });
            }).catch((err) => {
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error", extractError(err));
            });
        });
    }

    private onNavItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
        this.populateData(item.key);
    }

}