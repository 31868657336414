import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Label } from '@fluentui/react/lib/Label';
import { Link } from '@fluentui/react/lib/Link';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import authService from '../api-authorization/AuthorizeService'
import { Get, GetBlob, GetHtml } from '../../http';
import { createMarkup } from '../../functions';
import { DownloadDialog } from '../dialogs/download.dialog';
//import AccountWrapper from '../AccountWrapper';

export class Solution extends Component {
    static displayName = Solution.name;

    _downloadDialog = React.createRef();

    constructor(props, appState) {
        super(props, appState);

        let solution = (this.props.match.params) ? this.props.match.params.solution : "";
        this.loadPage(solution);

        this.state = {
            solution: "",
            solutionDisplayName: "",
            version: "",
            pubdate: null,
            //downloads: [],
            title: "",
            htmlContent: ""
        };

        //renderContent("<p>Hello</p>");


    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {

            //this.setState({
            //    solution: (this.props.match.params) ? this.props.match.params.solution : "",
            //    title: (this.props.match.params) ? this.props.match.params.solution : ""
            //});

            let solution = (this.props.match.params) ? this.props.match.params.solution : "";
            this.loadPage(solution);

            //renderContent("<p>Hello</p>");
        }
    }

    loadPage(solution) {
        authService.getAccessToken().then((token) => {

            Get("api/solutions/byname(" + solution + ")", token).then((data) => {
            //Get("secured/pages/solutions/"+ solution + "/" + solution + ".html", token).then((data) => {
                this.setState({
                    title: data.displayName,
                    solution: solution,
                    solutionDisplayName: data.displayName,
                    version: data.currentVersion,
                    pubdate: new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit"
                    }).format(new Date(data.publishDate))
                });
            }).catch((err) => {
                this.setState({ title: "Unknown Solution" });
            });

            /*
            Get("api/solutions/byname(" + solution + ")/downloads", token).then((files) => {
                this.setState({
                    downloads: files
                });
            }).catch((err) => {
                this.setState({
                    downloads: []
                });
            });
            */

            //GetHtml("pages/solutions/" + solution + "/" + solution + ".html", token).then((html) => {
            GetHtml("/pages/solutions/"+ solution + "/" + solution + ".html", token).then((html) => {
                this.setState({
                     htmlContent: html
                });
            }).catch((err) => {
                this.setState({ htmlContent: "Page not found" });
            });


        });
    }

    /*
    componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
        if (this.props !== nextProps) {           
            if (nextProps.match.params) {
                this.setState({ solution: nextProps.match.params.solution });
            }
        }
    }
    */

    /*
    componentDidMount() {
        if (this.props.match.params) {
            try {
                this.setState({ solution: this.props.match.params.solution });

                //this.setState({ loading: false });
            } catch (err) {
                //this.setState({ loading: false });
            }
        }
    }
    */

  render () {
    return (
        <div className="card" style={{ marginBottom: '10px' }}>

			<div className="card-header">
                <h4>{ this.state.title }</h4>
			</div>

			<div id="content" className="card-body">

                <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 250px)', width: '100%' }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

                        <div className="card" style={{ width: '15rem', float: 'right' }}>
                            <div className="card-header" style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '5px', paddingRight: '5px' }}>
                                <h6>Solution</h6>
                            </div>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div className="card-text" style={{ fontSize: '14px' }}>
                                    {/*<table cellpadding="0" cellspacing="0" border="0">
                                <tr><td valign="middle"><Label>Version:</Label></td><td>&nbsp;&nbsp;</td><td valign="middle">{this.state.version}</td></tr>
                                <tr><td valign="middle"><Label>Release date:</Label></td><td>&nbsp;&nbsp;</td><td valign="middle">{this.state.pubdate}</td></tr>
                            </table>*/}
                                    <table style={{ cellPadding: 0, cellSpacing: 0, border: 0 }}>
                                        <tbody>
                                            <tr><td valign="middle"><Label>Last updated:</Label></td><td>&nbsp;&nbsp;</td><td valign="middle">{this.state.pubdate}</td></tr>
                                        </tbody>
                                    </table>
                                    <Link onClick={(evt) => {
                                        evt.preventDefault();
                                        this.showDownloadDialog(this.state.solution, this.state.solutionDisplayName);
                                    }}>Download</Link>
                                </div>
                            </div>
                        </div>

                        {/*<AccountWrapper />*/}

                        <div dangerouslySetInnerHTML={createMarkup(this.state.htmlContent)} />
                        {/*<iframe style={{ width: '100%', height: '100%', border: '0px' }} src={ this.state.htmlFile }> </iframe>*/}
                    </ScrollablePane>
                </div >		


                <DownloadDialog ref={this._downloadDialog} ></DownloadDialog>

			</div>
		</div>
    );
    }

    showDownloadDialog(solution, solutionDisplayName) {
        this._downloadDialog.current.Show(solution, solutionDisplayName, (result) => {

        });
    }
}

/*
function renderContent(content) {
    clearRender();
    const htmlPlacement = ("div>" + content + "</div>");
    ReactDOM.render(htmlPlacement, document.getElementById('content'));
}

function clearRender() {
    ReactDOM.unmountComponentAtNode(document.getElementById('content'));
}
*/
