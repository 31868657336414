
import { convertBlobToBase64 } from './functions';

export function Send(method: string, url: string, body: any, token: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: method,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(body)
        };
        fetch(url, requestOptions)
            .then(res => {
                if (res.ok) {
                    if (res.status == 204) {
                        return null;
                    } else {
                        return res.json();
                    }
                } else {
                    return res.json().then(json => { throw json; });
                }
            })
            .then(data => resolve(data))
            .catch((err) => { reject(err); });
    });
}

export function GetBlobAsBase64(url: string, accessToken: string): Promise<string> {
    return new Promise((resolve, reject) => {
        GetBlob(url, accessToken).then((blob) => {
            let b64 = convertBlobToBase64(blob);
            resolve(b64);
        })
        .catch((error) => {
            reject(error)
        });
    });
}

export function GetBlob(url: string, token: string): Promise<Blob> {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: "GET",
            headers: { 'Accept': 'text/octet-stream', 'Authorization': 'Bearer ' + token }
        };
        fetch(url, requestOptions)
            .then(res => {
                if (res.ok) {
                    return res.blob();
                } else {
                    return res.text().then(txt => {
                        throw txt;
                    });
                }
            })
            .then(data => resolve(data))
            .catch((err) => { reject(err); });
    });
}

export function GetBlobsAsZip(url: string, fileNames: string[], token: string): Promise<Blob> {
    return new Promise(function (resolve, reject) {
        const reqOptions = {
            method: "POST",
            headers: { 'Accept': 'text/octet-stream', 'Content-type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(fileNames)
        };
        fetch(url, reqOptions)
            .then(res => {
                if (res.ok) {
                    return res.blob();
                } else {
                    return res.text().then(txt => {
                        throw txt;
                    });
                }
            })
            .then(data => resolve(data))
            .catch((err) => { reject(err); });
    });
}

export function GetHtml(url: string, token: string): Promise<string> {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: "GET",
            headers: { 'Accept': 'text/html', 'Authorization': 'Bearer ' + token }
        };
        fetch(url, requestOptions)
            .then(res => {
                if (res.ok) {
                    return res.text();
                } else {
                    return res.text().then(txt => { throw txt; });
                }
            })
            .then(data => resolve(data))
            .catch((err) => { reject(err); });
    });
}

export function Get(url: string, token: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        const requestOptions = {
            method: "GET",
            headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token }
        };
        fetch(url, requestOptions)
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then(json => { throw json; });
                }
            })
            .then(data => resolve(data))
            .catch((err) => { reject(err); });
    });
}

/*
export function DoRequest(method: string, url: string, body: any, token: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        let req = new XMLHttpRequest();
        req.open(method, url, true);
        req.setRequestHeader("Accept", "application/json");
        if ((["post", "patch", "put"]).indexOf(method.toLowerCase()) > -1) {
            req.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        }
        req.setRequestHeader("Authorization", "Bearer " + token);
        req.onreadystatechange = function () {
            if (this.readyState === 4) {
                req.onreadystatechange = null;
                if (this.status >= 200 && this.status <= 299) {
                    if (this.response != "")
                        resolve(JSON.parse(this.response));
                    else
                        resolve(null);
                } else {
                    reject({
                        status: this.status,
                        message: req.responseText
                    });
                }
            }
        };
        req.onerror = function () {
            reject({
                status: this.status,
                statusText: req.statusText
            });
        };
        if (body != null) {
            req.send(JSON.stringify(body));
        } else {
            req.send();
        }
    });
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

function reviver(key, value) {
    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
}
*/
