//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';

import { isEmptyStr } from '../../functions';
import { IUser } from '../pages/admin.users';

interface IDialogOutput {
    password: string;
}

interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    currentUser: IUser;

    newPassword: string;
    newPasswordConfirm: string;
}

export class Admin_ChangePasswordDialog extends React.Component<IDialogProps, IDialogState> {

    private _cbOk: { (args: IDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
        super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onOkClick = this._onOkClick.bind(this);
        this.validate = this.validate.bind(this);

        this.state = {
            title: "Change password",
            hide: true,
            invalid: true,
            currentUser: null,

            newPassword: "",
            newPasswordConfirm: ""

        };

    }

    public render(): JSX.Element {
        return (
            <Dialog
                hidden={this.state.hide}
                onDismiss={this._onCancelClick}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
                maxWidth="calc(100% - 32px)"
                modalProps={{
                    isBlocking: true,
                    //styles: { main: { maxWidth: 450 } }
                }}
            >

                <TextField key="newPassword" label="Password" autoComplete="new-password"
                    value={this.state.newPassword} maxLength={100} type="password"
                    canRevealPassword={true}
                    onChange={(e, newValue) => {
                        this.setState({ newPassword: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />
                <TextField key="newPasswordConfirm" label="Confirm password" type="password" autoComplete="new-password"
                    value={this.state.newPasswordConfirm} maxLength={100}
                    canRevealPassword={true}
                    onChange={(e, newValue) => {
                        this.setState({ newPasswordConfirm: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <DialogFooter>
                    <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                    <DefaultButton onClick={this._onCancelClick} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }

    reset() {
        let user = this.state.currentUser;
        this.setState({
            newPassword: "",
            newPasswordConfirm: ""
        });
    }

    validate() {
        let valid: boolean = (
            !isEmptyStr(this.state.newPassword) &&
            !isEmptyStr(this.state.newPasswordConfirm) &&
            (this.state.newPassword == this.state.newPasswordConfirm)
        );
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }

    private _onOkClick() {
        this.setState({ invalid: false, hide: true });
        this._cbOk({ password: this.state.newPassword });
    }

    public Show(user: IUser, onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, invalid: true, currentUser: user }, () => { this.reset(); });

    }

}