import * as React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';
import { Link } from '@fluentui/react/lib/Link';
import { DetailsList, DetailsListLayoutMode, ConstrainMode, IColumn, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps } from '@fluentui/react/lib/DetailsList';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
//import { CommandBarButton, PrimaryButton, Button, IButtonStyles } from '@fluentui/react/lib/Button';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';

import { copyAndSort, extractError } from '../../functions'
import { Get, Send } from '../../http';
import { ErrorDialog } from '../dialogs/error.dialog';
import { InfoDialog } from '../dialogs/info.dialog';
import { TrialDialog } from '../dialogs/trial.dialog';
import { LicenseKeyDialog } from '../dialogs/licensekey.dialog';
import { ExtensionDialog } from '../dialogs/extension.dialog';
import { DownloadDialog } from '../dialogs/download.dialog';
import authService from '../api-authorization/AuthorizeService'

interface ILicensesProps {
    firstname: string;
    lastname: string;
}

interface ILicensesState {
    items: ILicense[];
    columns: IColumn[]  
    selection: Selection,
    loading: boolean;
}


interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    solutionUniqueName: string;
    host: string;
    validTo: Date;
}

const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
    <TooltipHost {...tooltipHostProps} />
);

//registerIcons({
//    icons: custom_icons
//});

export class Licenses extends React.Component<ILicensesProps, ILicensesState> {

    static displayName = Licenses.name;

    private _errorDialog = React.createRef<ErrorDialog>();
    private _infoDialog = React.createRef<InfoDialog>();
    private _trialDialog = React.createRef<TrialDialog>();
    private _licenseKeyDialog = React.createRef<LicenseKeyDialog>();
    private _extensionDialog = React.createRef<ExtensionDialog>();
    private _downloadDialog = React.createRef<DownloadDialog>();

    constructor(props: ILicensesProps, appState: ILicensesState) {
        super(props, appState);

        this.requestTrial = this.requestTrial.bind(this);
        this.showLicenseKey = this.showLicenseKey.bind(this);
        this.onRenderItemColumn = this.onRenderItemColumn.bind(this);

        this.state = {
            items: [],
            loading: true,
            columns: this.getColumns(),
            selection: new Selection({
                onSelectionChanged: () => {
                    //this.setState({ canSave: this.state.selection.getSelectedCount() > 0 });
                },
                selectionMode: SelectionMode.single,
            })
        };

        //initializeIcons();
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        return (

		<div className="card">

			<div className="card-header">
				<h4>Your licenses</h4>
			</div>

			<div className="card-body">


                    <CommandBar
                        items={[
                            {
                                key: 'requesttrial',
                                text: 'Request trial',
                                ariaLabel: 'Request trial',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'PermissionsSolid' },
                                onClick: this.requestTrial
                            },
                            {
                                key: 'showlicensekey',
                                text: 'Show license key',
                                ariaLabel: 'Show license key',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'PermissionsSolid' },
                                /*style: { display: (this.state.selection.count > 0) ? 'block' : 'none' },*/
                                onClick: () => {
                                    let selected = this.state.selection.getSelection();//[0] as ILicense;
                                    if (selected.length > 0) {
                                        this.showLicenseKey(selected[0] as ILicense);
                                    } else {
                                        this._errorDialog.current.Show("Error", "No license selected");
                                    }
                                }
                            },
                            {
                                key: 'requestlicenseextension',
                                text: 'Request license extension',
                                ariaLabel: 'Request license extension',
                                cacheKey: 'myCacheKey',
                                iconProps: { iconName: 'PermissionsSolid' },
                                /*style: { display: (this.state.selection.count > 0) ? 'block' : 'none' },*/
                                onClick: () => {
                                    let selected = this.state.selection.getSelection();//[0] as ILicense;
                                    if (selected.length > 0) {
                                        this.requestExtension(selected[0] as ILicense);
                                    } else {
                                        this._errorDialog.current.Show("Error", "No license selected");
                                    }
                                }
                            }
                        ]}
                    />

                    <div key='divMain' style={{ position: "relative", height: 'calc(100vh - 300px)', width: '100%' }}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

                            <DetailsList
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                onRenderItemColumn={this.onRenderItemColumn}
                                compact={true}
                                columns={this.state.columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selection={this.state.selection}
                                constrainMode={ConstrainMode.unconstrained}
                                selectionPreservedOnEmptyClick={true}
                                items={this.state.items}
                            >
                            </DetailsList>

                        </ScrollablePane>
                    </div >		

                    {this.state.loading && (
                        <Stack {...{ horizontalAlign: 'start', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ paddingLeft: "10px" }}>
                            <Spinner size={SpinnerSize.large} />
                        </Stack>
                    )}

                    <ErrorDialog ref={this._errorDialog} ></ErrorDialog>
                    <InfoDialog ref={this._infoDialog} ></InfoDialog>
                    <TrialDialog ref={this._trialDialog} ></TrialDialog>
                    <LicenseKeyDialog ref={this._licenseKeyDialog} ></LicenseKeyDialog>
                    <ExtensionDialog ref={this._extensionDialog} ></ExtensionDialog>
                    <DownloadDialog ref={this._downloadDialog} ></DownloadDialog>

			</div>
		</div>

            
        );
    }

    private onRenderDetailsHeader(props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        if (!props) {
            return null;
        }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    }

    private onRenderItemColumn(item: ILicense, index?: number, column?: IColumn) {
        if (column) {
            

            if (column.fieldName == "validTo") {
                return <div>{new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(new Date(item.validTo))}</div>;
            /*} else if (column.key == "download") {
                if (new Date(item.validTo) > new Date()) {
                    return <Link onClick={(evt) => {
                        evt.preventDefault();
                        this.showDownloadDialog(item.solutionUniqueName, item.solutionDisplayName);
                    }}>Download</Link>
                } else {
                    return <span></span>
                }*/
            } else {
                const fieldContent = item[column.fieldName as keyof ILicense];
                return <div>{fieldContent}</div>;
            }
        }
    }

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    }

    private getColumns(): IColumn[] {
        return [
            {
                key: 'solutionName',
                name: 'Solution',
                fieldName: 'solutionDisplayName',
                minWidth: 150,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'validTo',
                name: 'Valid to',
                fieldName: 'validTo',
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            {
                key: 'host',
                name: 'Environment',
                fieldName: 'host',
                minWidth: 150,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick,
            },
            /*{
                key: 'download',
                name: '',
                fieldName: 'solutionUniqueName',
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false
            }*/

        ];
    }

    async populateData() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/licenses", token).then((data) => {
                this.setState({ items: data, loading: false });
            });
        });

        //const response = await fetch('test1');
        //const data = await response.json();
        
    }

    requestTrial() {

        this._trialDialog.current.Show((info) => {

            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                Send("POST", "api/licenses/trial", info, token).then((data) => {
                    this.setState({ loading: false });
                    this.populateData();
                }).catch((err) => {                    
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Request Trial", extractError(err));
                });
            });

        });
    }

    requestExtension(license: ILicense) {
        this._extensionDialog.current.Show({ userLicenseId: license.userLicenseId, solutionId: license.solutionId, solutionDisplayName: license.solutionDisplayName, host: license.host, validTo: license.validTo }, (result) => {
            this.setState({ loading: true });
            authService.getAccessToken().then((token) => {
                let data = { "UserLicenseId": license.userLicenseId, Reason: result.Reason };
                Send("POST", "api/licenses/requestextension", data, token).then((data) => {
                    this.setState({ loading: false });
                    this._infoDialog.current.Show("Request license extension", data.message, true);
                }).catch((err) => {
                    this.setState({ loading: false });
                    this._errorDialog.current.Show("Error Request license extension", extractError(err));
                });
            });

        });
    }

    showLicenseKey(license: ILicense) {
        this._licenseKeyDialog.current.Show({ solutionDisplayName: license.solutionDisplayName, userLicenseId: license.userLicenseId });
    }

    showDownloadDialog(solution: string, solutionDisplayName: string) {
        this._downloadDialog.current.Show(solution, solutionDisplayName, (result) => {

        });
    }

}

