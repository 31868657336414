//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField, ITextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react/lib/Label';

import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'
import { copyToClipboard } from '../../functions';

export interface IDialogOutput {

}

export interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    licensekey: string;
    loading: boolean;
    licenseInfo: ILicenseKeyInfo
}

interface ILicenseKeyInfo {
    userLicenseId: string;
    //solutionId: string;
    solutionDisplayName: string;
    host: string;
    userCount: number;
    validTo: Date;
}

/*
interface ILicense {
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    host: string;
    validTo: Date;
}

*/

export class Admin_LicenseKeyDialog extends React.Component<IDialogProps, IDialogState> {

    private _licenseKeyRef = React.createRef<ITextField>();

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onCopyKeyClick = this._onCopyKeyClick.bind(this);
        this._onCopyAllClick = this._onCopyAllClick.bind(this);

      this.state = {
          title: "",
          hide: true,
          loading: true,
          licensekey: "",
          licenseInfo: null
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title
                }}
                maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >

                {this.state.loading && (
                    <Stack {...{ horizontalAlign: 'start', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} style={{ paddingLeft: "10px" }}>
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                )}

                {!this.state.loading && (
                    <>
                    <table cellPadding="0" cellSpacing="0" style={{ border: '0px' }}>
                        <tbody>
                            <tr><td style={{ textAlign: "left" }}><Label>Solution: </Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.licenseInfo.solutionDisplayName}</td></tr>
                            <tr><td style={{ textAlign: "left" }}><Label>Environment:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.licenseInfo.host}</td></tr>
                            <tr><td style={{ textAlign: "left" }}><Label>Users:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{this.state.licenseInfo.userCount}</td></tr>
                                <tr><td style={{ textAlign: "left" }}><Label>Valid to:</Label></td><td>&nbsp;&nbsp;</td><td style={{ textAlign: "left" }}>{new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                }).format(new Date(this.state.licenseInfo.validTo))}</td></tr>
                        </tbody>
                    </table>

                    <Label>License key:</Label>
                    <TextField multiline autoAdjustHeight value={this.state.licensekey} componentRef={this._licenseKeyRef} />

                    </>
                )}
           
              <DialogFooter>
                    <PrimaryButton onClick={this._onCopyKeyClick} text="Copy key" />
                    <PrimaryButton onClick={this._onCopyAllClick} text="Copy all" />
                    <DefaultButton onClick={this._onCancelClick} text="Close" />
              </DialogFooter>
            </Dialog>      
        );
    }
 
    private _onCopyKeyClick() {
        copyToClipboard(this.state.licensekey);
        /*
        if (this._licenseKeyRef.current != null) {
            this._licenseKeyRef.current.select();
            document.execCommand('copy');
        }
        */
    }

    private _onCopyAllClick() {
        let info = this.state.licenseInfo;
        let validTo = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit"
        }).format(new Date(info.validTo));

        let data = `Solution: ${info.solutionDisplayName}\r\nEnvironment: ${info.host}\r\nUsers: ${info.userCount}\r\nValid to: ${validTo}\r\nLicense key:\r\n${this.state.licensekey}\r\n`;

        copyToClipboard(data);
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }

    public Show(licenseInfo: ILicenseKeyInfo): void {
        let title = "License";
        this.setState({ hide: false, loading: true, title: title, licensekey: "" });

          authService.getAccessToken().then((token) => {
              Get("api/admin/licenses/" + licenseInfo.userLicenseId + "/licensekey", token).then((data) => {
                  this.setState({ loading: false, licensekey: data.licenseKey, licenseInfo: licenseInfo });
              });
          });

      }

}