//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';

import { isEmptyStr } from '../../functions';
import { IUser } from '../pages/admin.users';

interface IDialogOutput {
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    companyName: string;
}

interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    currentUser: IUser;

    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    companyName: string;
    //enabled: boolean;
}

export class Admin_ChangeUserDialog extends React.Component<IDialogProps, IDialogState> {

    private _cbOk: { (args: IDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
        super(props, appState);

        this._onCancelClick = this._onCancelClick.bind(this);
        this._onOkClick = this._onOkClick.bind(this);
        this.validate = this.validate.bind(this);

        this.state = {
            title: "Change user",
            hide: true,
            invalid: true,
            currentUser: null,
            firstName: null,
            lastName: null,
            email: null,
            jobTitle: null,
            companyName: null,
            //enabled: null
        };

    }

    public render(): JSX.Element {
        return (
            <Dialog
                hidden={this.state.hide}
                onDismiss={this._onCancelClick}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
                maxWidth="calc(100% - 32px)"
                modalProps={{
                    isBlocking: true,
                    //styles: { main: { maxWidth: 450 } }
                }}
            >

                <TextField key="company" label="Company"
                    value={this.state.companyName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ companyName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="firstName" label="First name"
                    value={this.state.firstName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ firstName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="lastName" label="Last name"
                    value={this.state.lastName} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ lastName: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="jobTitle" label="Job Title"
                    value={this.state.jobTitle} maxLength={100}
                    onChange={(e, newValue) => {
                        this.setState({ jobTitle: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <TextField key="email" label="E-mail address"
                    value={this.state.email} maxLength={250}
                    onChange={(e, newValue) => {
                        this.setState({ email: newValue }, () => { this.validate(); });
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value: string) => {
                        return "";
                    }}
                />

                <br />

                <DialogFooter>
                    <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                    <DefaultButton onClick={this._onCancelClick} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }

    reset() {
        let user = this.state.currentUser;
        this.setState({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            jobTitle: user.jobTitle,
            companyName: user.companyName
            //enabled: null
        });
    }

    validate() {
        let valid: boolean = (
            !isEmptyStr(this.state.companyName) &&
            !isEmptyStr(this.state.firstName) &&
            !isEmptyStr(this.state.lastName) &&
            !isEmptyStr(this.state.jobTitle) &&
            !isEmptyStr(this.state.email)
        );
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }

    private _onOkClick() {
        this.setState({ invalid: false, hide: true });
        this._cbOk({ companyName: this.state.companyName, firstName: this.state.firstName, lastName: this.state.lastName, jobTitle: this.state.jobTitle, email: this.state.email });
    }

    public Show(user: IUser, onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, invalid: true, currentUser: user }, () => { this.reset(); });

    }

}