//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Link } from '@fluentui/react/lib/Link';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { extractError, isEmptyStr, replaceAll } from '../../functions';
import { Get, GetBlob, GetBlobsAsZip, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService';
import { ErrorDialog } from './error.dialog';
import { Label } from '@fluentui/react';

export interface IDialogOutput {

}

export interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    solution: string;
    solutionDisplayName: string;
    files: IDownloadFileModel[];
    loading: boolean;
}

interface IDownloadFileModel {
    fileName: string;
    dateTime: any;
}

export class DownloadDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbClose: { (args: IDialogOutput): void; };
    private _errorDialog = React.createRef<ErrorDialog>();

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onCloseClick = this._onCloseClick.bind(this);
        this.download = this.download.bind(this);
        this.downloadAll = this.downloadAll.bind(this);

      this.state = {
          title: "Download",
          hide: true,
          solution: "",
          solutionDisplayName: "",
          files: [],
          loading: true
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCloseClick}
              dialogContentProps={{
                type: DialogType.normal,
                  title: this.state.title, //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                  /*styles: { main: { maxWidth: 800 } },*/
              }}
            >

                <Label>{this.state.solutionDisplayName}</Label>
                <table className="downloadTable">
                    <thead><tr><th>Publish date</th><th>Solution file</th></tr></thead>
                    <tbody>
                {
                    (this.state.files.length == 0) ? <span>No downloads available</span> :
                        this.state.files.map(f => {
                            return <tr><td>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "2-digit" }).format(new Date(f.dateTime)) }</td><td><Link key={f.fileName} onClick={(evt) => {
                                evt.preventDefault();
                                this.download(this.state.solution, f);
                            }}>{f.fileName}</Link></td></tr>
                        })
                        }
                </tbody>
                </table>
                {this.state.loading && <Spinner size={SpinnerSize.large} />}

                <ErrorDialog ref={this._errorDialog} />

                <DialogFooter>
                    {(this.state.files.length > 1 && (
                        <DefaultButton onClick={this.downloadAll} text="Download all" />
                    )
                    )}
                    &nbsp;&nbsp;&nbsp;  
                    <DefaultButton onClick={this._onCloseClick} text="Close" />
              </DialogFooter>
            </Dialog>      
        );
    }
  
    private _onCloseClick() {
        this.setState({ hide: true });            
        this._cbClose({ });
    }
   
    public Show(solution: string, solutionDisplayName: string, onClose: { (args: IDialogOutput): void; }): void {
        this._cbClose = onClose;

        this.setState({ hide: false, solution: solution, solutionDisplayName: solutionDisplayName });

        authService.getAccessToken().then((token) => {
            Get("api/solutions/byname(" + solution + ")/downloads", token).then((files) => {
                this.setState({ files: files, loading: false });
            }).catch((err) => {
                this.setState({ files: [], loading: false });
            });
        });
    }

    download(solution, fileName) {
        this.setState({ loading: true });
        authService.getAccessToken().then((token) => {
            GetBlob("api/solutions/byname(" + solution + ")/downloadfile?file=" + encodeURIComponent(fileName), token).then((blob) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.setAttribute("download", fileName);
                a.click();
                this.setState({ loading: false });
            }).catch((err) => {
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error", extractError(err));
            });
        });
    }

    downloadAll() {
        this.setState({ loading: true });
        let solution = this.state.solution;
        let fn = replaceAll(this.state.solutionDisplayName, " ", "_")+".zip"
        authService.getAccessToken().then((token) => {

            GetBlobsAsZip("api/solutions/byname(" + solution + ")/downloadfilesaszip", this.state.files.map(f => f.fileName), token).then((blob) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.setAttribute("download", fn);
                a.click();
                this.setState({ loading: false });
            }).catch((err) => {
                this.setState({ loading: false });
                this._errorDialog.current.Show("Error", extractError(err));
            });

        });
    }
}