import * as React from 'react';

import { DetailsList, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import authService from '../api-authorization/AuthorizeService';
import { ErrorDialog } from '../dialogs/error.dialog';
import { Get, GetHtml, GetBlob } from '../../http';
import { createMarkup, extractError, endsWithOneOf } from '../../functions';
interface IProps {

}

type TFileType = "html" | "pdf" | "other" | "none";

interface IState {
    loading: boolean;
    items: IItem[];
    navs: IBreadcrumbItem[];
    htmlContent: string;
    pdfUrl: string;
    fileType: TFileType;
}

interface IItemResult {
    //ParentPath: string;
    currentPath: string;
    items: IItem[];
}

interface IItem {
    parentPath: string;
    name: string;
    type: string;
    //Date: string;
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const renderFileIcon = (name: string) => {
    const ext = /\.([a-z]+)/.exec(name)
    if (ext == null || ext.length == 0) return <Icon iconName="FabricFolder" />
    return (
        <img
            src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${ext[1]}.svg`}
            className={classNames.fileIconImg}
            alt={`file icon`}
        />
    )
}

export class KbExplorer extends React.Component<IProps, IState> {

    private _errorDialog = React.createRef<ErrorDialog>();

    constructor(props: IProps, appState: IState) {
        super(props, appState);

        this.onNavItemClicked = this.onNavItemClicked.bind(this);

        this.state = {
            loading: true,
            items: [],
            navs: [],
            htmlContent: "",
            fileType: "none",
            pdfUrl: ""
        };


    }

    componentDidMount() {
        this.populateData('');
    }

    async populateData(parentPath: string) {
        let _self = this;
        authService.getAccessToken().then((token) => {            
            Get("api/kb/explore?path="+encodeURIComponent(parentPath), token).then((data: IItemResult) => {

                let path = '';  
                let navs: IBreadcrumbItem[] = [
                    { key: '', text: 'KB', onClick: _self.onNavItemClicked }
                ].concat(
                    data.currentPath.split('\\').filter(f => f != '').map((p, i) => {
                        if (path === '') {
                            path = p;
                        } else {
                            path = path + '\\' + p;
                        }
                        return { key: path, text: p, onClick: _self.onNavItemClicked };
                    })
                );
                _self.setState({ items: data.items, navs: navs, loading: false });
            });
        });
    }

    render() {
        let maxHeight = 'calc(100vh - 300px)';
        return (
            <div className="card" style={{ marginBottom: '10px' }}>

                <div className="card-header">
                    <h4>Knowledge Base</h4>
                </div>

                <div id="content" className="card-body">



                                    <Breadcrumb styles={{
                                        itemLink: { fontSize: 12 }, chevron: { fontSize: 12 }, list: { fontSize: 12 }, listItem: { fontSize: 12 }, item: { fontSize: 12 }, overflow: { fontSize: 12 }, overflowButton: { fontSize: 12 }, root: { fontSize: 12 }
                                    }}
                                        items={this.state.navs}
                                        //maxDisplayedItems={2}
                                        //overflowIndex={((this.state.navs.length<2)?0:1)}
                                        overflowAriaLabel="More links"

                                    />

                    <div key='divMain' style={{ position: "relative", height: maxHeight, width: '100%', display: 'grid', gridTemplateColumns: '33% 67%' }}>

                        <div style={{ gridColumn: 1 }}>

                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ width: '33%' }}>

                            <DetailsList
                                items={this.state.items}
                                compact={true}
                                columns={[
                                    {
                                        key: 'column1',
                                        name: 'File Type',
                                        className: classNames.fileIconCell,
                                        iconClassName: classNames.fileIconHeaderIcon,
                                        iconName: 'Page',
                                        isIconOnly: true,
                                        minWidth: 16,
                                        maxWidth: 16,
                                        onRender: (item: IItem) => renderFileIcon(item.name),
                                    },
                                    {
                                        key: 'column2',
                                        name: 'Name',
                                        fieldName: 'name',
                                        minWidth: 210,
                                        maxWidth: 350,
                                        onRender: (item: IItem) => <span onClick={(ev) => {
                                            if (item.type == "Folder") {
                                                this.loadFolder(item);
                                            } else {
                                                this.loadFile(item);
                                            }
                                        }}>{item.name}</span>
                                    },
                                    //{
                                    //    key: 'column3',
                                    //    name: 'Date',
                                    //    minWidth: 70,
                                    //    maxWidth: 200,
                                    //    onRender: (item: IItem) => <span>{item.Date}</span>
                                    //},
                                    // and so on for more columns
                                ]}
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                //onItemInvoked={(item: IItem) => {
                                //    if (item.type == "Folder") {
                                //        this.loadFolder(item);
                                //    } else {
                                //        this.loadFile(item);
                                //    }
                                //}}
                                />

                                 <ErrorDialog ref={this._errorDialog} />

                                { this.state.loading && (
                                    <Stack {...{ horizontalAlign: 'center', verticalAlign: 'center' }} tokens={{ childrenGap: 10 }} >
                                        <Spinner size={SpinnerSize.large} />
                                        <span>Loading...</span>
                                    </Stack>
                                )}                                                  

                                      </ScrollablePane>                     
                            </div>
                            <div style={{ gridColumn: 2, paddingLeft: 10 }}>
                            {(this.state.fileType == "html" && 
                                <div dangerouslySetInnerHTML={createMarkup(this.state.htmlContent)} style={{ height: maxHeight, width: '100%', overflow: 'scroll' }} />
                            )}
                            {(this.state.fileType == "pdf" &&
                                <iframe id="frmPdf" title="Pdf" src={this.state.pdfUrl} style={{ border: 0, height: maxHeight, width:'100%' } }></iframe>
                            )}
                            </div>

                        </div>
                </div>
            </div>
        );
    }

    loadFolder(item: IItem) {
        this.setState({
            htmlContent: "",
            fileType: "html", pdfUrl: ""
        });
        let path = (item.parentPath === '') ? item.name : item.parentPath + '\\' + item.name;
        this.populateData(path);
    }

    loadFile(item: IItem) {
        this.setState({
            htmlContent: "",
            fileType: "html", pdfUrl: ""
        });
        let _self = this;
        authService.getAccessToken().then((token) => {
            let path = (item.parentPath === '') ? item.name : item.parentPath + '\\' + item.name;
            let inline = [".html", ".htm", ".txt", ".xml", ".json"];
            if (endsWithOneOf(item.name, inline)) {
                GetHtml("/pages/kb/" + path, token).then((html) => {
                    if (item.name.endsWith(".txt") || item.name.endsWith(".json") || item.name.endsWith(".xml")) {
                        html = "<h3>" + item.name + "</h3><br><pre>" + html + "</pre>";
                    }
                    _self.setState({
                        htmlContent: html,
                        fileType: "html"
                    });
                }).catch((err) => {
                    _self.setState({ htmlContent: "Page not found", fileType: "html", pdfUrl: "" });
                });
            } else {
                _self.setState({ loading: true });
                GetBlob("/pages/kb/" + path, token).then((blob) => {
                    if (item.name.endsWith(".pdf")) { 
                        let u = URL.createObjectURL(blob);
                        _self.setState({
                            pdfUrl: u + "#toolbar=1&amp;navpanes=0&amp;zoom=100",
                            fileType: "pdf"
                        });
                        _self.setState({ loading: false });
                    } else {
                        var a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.setAttribute("download", item.name);
                        a.click();
                        _self.setState({ loading: false });
                    }
                }).catch((err) => {
                    _self.setState({ htmlContent: "File not found", fileType: "html", pdfUrl: "", loading: false });
                });
            }
        });
    }

    private onNavItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
        this.populateData(item.key);
    }

}