import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, NavDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { TooltipHost, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { ContextualMenu, ContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { IconButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { LinkContainer } from 'react-router-bootstrap'
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

    constructor(menuProps, menuState) {
        super(menuProps, menuState);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closePanel = this.closePanel.bind(this);

        this.state = {
            collapsed: true,
            showUsermenu: false
        };
    }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    closePanel(evt) {
        this.setState({ showUsermenu: false });
    }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/"><img src="/images/logo.gif" alt="Home" className="logo-style" />&nbsp;&nbsp;Portal</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                    <TooltipHost
                        content="Home"
                        id="tooltip_home"
                        calloutProps={{ gapSpace: 0 }}
                        styles={{ root: { display: 'inline-block' } }}
                    >
                        <LinkContainer to={"/"}>
                            <IconButton
                                ariaLabel="Home" style={{ color: 'inherit', textDecoration: 'inherit' }}
                                iconProps={{ iconName: 'HomeSolid' }}
                                styles={{ icon: {color: 'rgb(0, 120, 212)', fontSize: 24 }}}
                            />
                        </LinkContainer>
                    </TooltipHost>
                </NavItem>
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
