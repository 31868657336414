//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
//import { Toggle } from '@fluentui/react/lib/Toggle';

import { isEmptyStr } from '../../functions';
import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'

export interface IDialogOutput {
    SolutionId: string;
    Host: string;
}

export interface IDialogProps {

}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    solutions: IDropdownOption[];
    solutionId: string;
    host: string;
}

export class TrialDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IDialogOutput): void; };

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

      this._onCancelClick = this._onCancelClick.bind(this);
      this._onOkClick = this._onOkClick.bind(this);
      this.validate = this.validate.bind(this);

      this.state = {
          title: "Request Trial",
          hide: true,
          invalid: true,
          solutions: [],
          solutionId: null,
          host: null
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
                maxWidth="calc(100% - 32px)"
                modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >
                <Dropdown label="Solution" selectedKey={this.state.solutionId} options={this.state.solutions} required
                    onChange={(evt: any, option?: IDropdownOption, index?: number) => {
                        this.setState({ solutionId: option.data }, () => { this.validate() } );
                    }}
                />

                <TextField
                    label="Environment url (for example: https://contoso.crm4.dynamics.com)"
                    required
                    minLength={18}
                    maxLength={250}
                    value={this.state.host}
                    onChange={(e, newValue) => {
                        this.setState({ host: newValue }, () => { this.validate() });
                    }}
                />

                {/*<TextField
                    label="Environment url"
                    prefix="https://"
                    suffix=".dynamics.com"
                    required
                    minLength={5}
                    maxLength={100}
                    value={ this.state.host } 
                    onChange={(e, newValue) => {
                        this.setState({ host: newValue }, () => { this.validate()});
                    }}
                />*/}
                           
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    validate() {
        let valid: boolean = (this.state.solutionId != null && !isEmptyStr(this.state.host));
        if (valid) {
            let host = this.state.host.toLowerCase();
            valid = host.indexOf(".dynamics.com") > 0 && host.startsWith("https://");
        }
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });
        this._cbOk({ SolutionId: this.state.solutionId, Host: this.state.host.toLowerCase() });
    }
   
    public Show(onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, host: null, solutionId: null, invalid: true, solutions: [] });

        authService.getAccessToken().then((token) => {
            Get("api/licenses/solutions", token).then((solutions) => {
                this.setState({ solutions: solutions.map(function (m) { return { key: m.solutionId, data: m.solutionId, text: m.displayName } }) });
            });
        });

    }

}