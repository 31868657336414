//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TagPicker, IBasePicker, ITag } from "@fluentui/react/lib/Pickers";
import { Label } from '@fluentui/react/lib/Label';

import { isEmptyStr, stringtoNumber, formatNumber } from '../../functions';
import { Get, Send } from '../../http';
import authService from '../api-authorization/AuthorizeService'

interface IDialogOutput {
    userId: string;
}

interface IDialogProps {

}

interface ILicense {
    userId: string;
    userLicenseId: string;
    solutionId: string;
    solutionDisplayName: string;
    solutionUniqueName: string;
    host: string;
    validTo: Date;
    userCount: number;
}

interface IUserTag extends ITag {
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    enabled: boolean;
}

interface IDialogState {
    title: string;
    hide: boolean;
    invalid: boolean;
    currentLicense?: ILicense;
    userId: IUserTag;
    users: IUserTag[];
}

export class Admin_ChangeLicenseOwnerDialog extends React.Component<IDialogProps, IDialogState> {
 
    private _cbOk: { (args: IDialogOutput): void; };
    private _userPicker = React.createRef<IBasePicker<IUserTag>>();

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

      this._onCancelClick = this._onCancelClick.bind(this);
      this._onOkClick = this._onOkClick.bind(this);
      this.validate = this.validate.bind(this);

      this.state = {
          title: "Change owner",
          hide: true,
          invalid: true,
          userId: null,
          users: []
      }

      this.loadUsers();

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onCancelClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title //getLabel(this.props.lcid, "action_rename")
                }}
              maxWidth="calc(100% - 32px)"
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >


                <Label>Contact</Label>
                <TagPicker
                    selectedItems={(this.state.userId) ? [this.state.userId] : []}
                    itemLimit={1}
                    onResolveSuggestions={(filter?: string, selectedItems?: IUserTag[]) => {
                        return this.searchUser(filter, selectedItems);
                    }}
                    getTextFromItem={item => item.name}
                    onDismiss={(ev?: any, selectedItem?: IUserTag) => {
                        return false; // prevent adding tag on dismiss
                    }}
                    pickerSuggestionsProps={{
                        noResultsFoundText: "Contact not found",
                    }}
                    resolveDelay={300}
                    inputProps={{
                        required: true,
                        autoComplete: "false"
                    }}
                    onBlur={() => { // clears typed text 
                        if (this._userPicker.current) {
                            let ctrl: any = this._userPicker.current;
                            if (ctrl.input.current) {
                                ctrl.input.current._updateValue("");
                            }
                        }
                    }}
                    onEmptyInputFocus={(selectedItems?: IUserTag[] | undefined) => {
                        if (selectedItems && selectedItems.length > 0) {
                            return [];
                        }
                        return this.searchUser(undefined, selectedItems);
                    }}
                    onChange={(items: IUserTag[]) => {
                        if (items && items.length > 0) {
                            this.setState({ userId: items[0] }, () => { this.validate(); });
                        } else {
                            this.setState({ userId: null }, () => { this.validate(); });
                        }
                    }}
                />
                          
              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" disabled={this.state.invalid} />
                <DefaultButton onClick={this._onCancelClick} text="Cancel" />
              </DialogFooter>
            </Dialog>      
        );
    }

    validate() {
        let valid: boolean = (this.state.userId && this.state.userId !== null  && this.state.userId.key as string !== this.state.currentLicense.userId);
        this.setState({ invalid: !valid });
    }

    private _onCancelClick() {
        this.setState({ hide: true });
    }
  
    private _onOkClick() {
        this.setState({ invalid: false, hide: true });            
        this._cbOk({ userId: this.state.userId.key as string  });
    }
   
    public Show(license: ILicense, onOk: { (args: IDialogOutput): void; }): void {
        this._cbOk = onOk;

        this.setState({ hide: false, userId: null, currentLicense: license });
    }

    loadUsers() {
        let _self = this;
        authService.getAccessToken().then((token) => {
            Get("api/admin/users", token).then((data: any[]) => {
                let users: IUserTag[] = data.map(s => {
                    return { key: s.userId, name: s.fullName + " (" + s.companyName + ")", fullName: s.fullName, firstName: s.firstName, lastName: s.lastName, email: s.email, companyName: s.companyName, enabled: s.enabled && !s.deleted };
                });
                _self.setState({ users: users });
            });
        });
    }

    searchUser(filter?: string, selectedItems?: IUserTag[]): IUserTag[] {
        if (filter && filter != "") {
            return this.state.users.filter(s => s.firstName.toLowerCase().includes(filter) || s.lastName.toLowerCase().includes(filter) || s.email.toLowerCase().includes(filter) || s.companyName.toLowerCase().includes(filter));
        } else {
            return this.state.users;
        }
    }

}