import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

import { Home } from './components/pages/Home';
//import { FetchData } from './components/pages/FetchData';
//import { Counter } from './components/pages/Counter';
import { Licenses } from './components/pages/Licenses';
import { Solution } from './components/pages/Solution';
import { Knowledgebase } from './components/pages/Knowledgebase';
import { DownloadExplorer } from './components/pages/DownloadExplorer';
import { KbExplorer } from './components/pages/KbExplorer';
import { Admin_UserLicenses } from './components/pages/admin.userlicenses';
import { Admin_Users } from './components/pages/admin.users';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { custom_icons } from './custom_icons';

import './custom.css'

registerIcons({
    icons: custom_icons
});

export default class App extends Component {
  static displayName = App.name;

    constructor(appProps, appState) {
        super(appProps, appState);

        initializeIcons();
    }


  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <AuthorizeRoute path='/admin/licenses' component={Admin_UserLicenses} />
            <AuthorizeRoute path='/admin/users' component={Admin_Users} />
            <AuthorizeRoute path='/licenses' component={Licenses} />
            <AuthorizeRoute path='/solutions/:solution' component={Solution} />
            <AuthorizeRoute path='/knowledgebase/:solution/:page' component={Knowledgebase} />
            <AuthorizeRoute path='/pages' />
            <AuthorizeRoute path='/download-explorer' component={DownloadExplorer} />
            <AuthorizeRoute path='/kb-explorer' component={KbExplorer} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
