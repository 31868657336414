//import React from 'react';
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

export interface IDialogOutput {

}

export interface IDialogProps {

}

interface IDialogState {
  title: string;
  hide: boolean;
    message: string;
    isHtml: boolean;
}

export class InfoDialog extends React.Component<IDialogProps, IDialogState> {

    constructor(props: IDialogProps, appState: IDialogState) {
      super(props, appState);

        this._onOkClick = this._onOkClick.bind(this);

      this.state = {
          title: "",
          hide: true,
          message: "",
          isHtml: false
      }

    }

    public render(): JSX.Element {
        return (
            <Dialog
              hidden={this.state.hide}
              onDismiss={this._onOkClick}
              dialogContentProps={{
                type: DialogType.normal,
                title: this.state.title
              }}
              modalProps={{
                isBlocking: true,
                //styles: { main: { maxWidth: 450 } }
              }}
            >
                {this.state.isHtml && (
                    <div dangerouslySetInnerHTML={{ __html: this.state.message }}></div>              
                )}

                {!this.state.isHtml && (
                    <div>{this.state.message}</div>              
                )}

              <DialogFooter>
                <PrimaryButton onClick={this._onOkClick} text="OK" />
              </DialogFooter>
            </Dialog>      
        );
    }
 
      private _onOkClick() {
          this.setState({ hide: true });
      }
   
      public Show(title: string, message: string, isHtml?: boolean): void {
           this.setState( { title: title, message: message, hide: false, isHtml: (isHtml)?true:false } );
      }

}