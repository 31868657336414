import * as React from 'react';

export const custom_icons: any = {

    'flow-svg': (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.341712 2.93492L4.33615 7.5L0.341712 12.0651C-0.318342 12.8194 0.217367 14 1.21972 14H9.94803C10.2845 14 10.6045 13.8548 10.826 13.6016L15.4927 8.26826C15.8776 7.82839 15.8776 7.17161 15.4927 6.73174L10.826 1.39841C10.6045 1.14523 10.2845 1 9.94803 1H1.21972C0.217367 1 -0.318342 2.18058 0.341712 2.93492ZM1.09429 2.27642C0.999996 2.16865 1.07653 2 1.21972 2H9.12055L4.99937 6.73936L1.09429 2.27642ZM5.37784 7.82809L10.2354 2.24195L12.3536 4.66276L4.94269 13H1.21972C1.07652 13 0.999996 12.8313 1.09429 12.7236L5.37683 7.82925L5.37784 7.82809ZM6.28065 13H9.94803C9.99609 13 10.0418 12.9793 10.0735 12.9431L14.7401 7.60975C14.7951 7.54691 14.7951 7.45309 14.7401 7.39025L13.0173 5.42129L6.28065 13Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),

    'quote-svg': (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs><style>{".cls-1{fill:none;}"}</style></defs><title>Quotes</title><rect className="cls-1" width="16" height="16" /><path d="M11.5,16A4.5,4.5,0,1,1,16,11.5,4.5,4.5,0,0,1,11.5,16Zm0-8A3.5,3.5,0,1,0,15,11.5,3.5,3.5,0,0,0,11.5,8Z" /><path d="M8,5H5V4H8ZM4,8H3V9H4ZM4,4H3V5H4ZM4,6H3V7H4Zm0,4H3v1H4Zm2,0H5v1h.78A5.7,5.7,0,0,1,6,10Zm1-2H5V9H6.33A5.75,5.75,0,0,1,6.95,8ZM9.83,6H5V7H7.93A5.74,5.74,0,0,1,9.83,6Zm3.67,5H12V9H11v3h2.5Zm-2,5H1V0H9.71L14,4.29V8.38a4,4,0,0,0-1-.59V5H9V1H2V15H9.57a4,4,0,0,0,1.93.5ZM10,4h2.29L10,1.71Z" /></svg>
    )

}; 